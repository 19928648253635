<template>
    <div class="footer-box">
        <div class="main-box">
           <div class="fw">
               <div>
<!--                 :src=logob-->
                 <div><img style="width:270px" src="../assets/images/home/logob.png"
                             alt="新区智慧金融综合服务平台"></div>
               </div>
               <div class="fwr">
                   <div>致力于打造产业数字金融新生态</div>
                   <div class="line"><img src="../assets/images/footer/line.png" alt=""></div>
               </div>
           </div>
            <div class="fb">
                <div class="fbl">
                    <p class="fbt">联系方式</p>
                    <p class="fbc">QQ在线客服 243677067</p>
                    <p class="fbc">客服热线：0451-81023000/18245108077</p>
                    <p class="fbc">工作时间：周一至周五 09:00-17:30</p>
                </div>
                <div class="fbr">
                    <p class="fbt">平台资料</p>
                  <p class="fbc">
                    <a href="/tpls/企业个体工商户用户操作手册.docx">企业/个体工商户端用户使用手册<img src="/images/download.png"></a>
                  </p>
                  <p class="fbc">
                    <a href="/tpls/金融机构操作流程手册.docx">金融端用户使用手册<img src="/images/download.png"></a>
                  </p>
                  <p class="fbc">
                    <a href="/tpls/龙哈智能产融综合服务平台隐私权政策.docx">隐私权政策<img src="/images/download.png"></a>
                  </p>
                  <p class="fbc">
                    <a href="/tpls/龙哈智能产融综合服务平台客户权益保障承诺书.docx">客户权益保障承诺书<img src="/images/download.png"></a>
                  </p>
                  <p class="fbc">
                    <a href="/tpls/最终用户使用条款.docx">用户使用条款<img src="/images/download.png"></a>
                  </p>



<!--                    <p class="fbc" style="cursor: pointer" @click="showPDF = true, pdfSrc='http://39.105.197.176:3080/crpt30/files/法律声明.pdf'">用户协议与法律声明</p>-->
<!--                    <p class="fbc" style="cursor: pointer" @click="showPDF = true, pdfSrc='http://39.105.197.176:3080/crpt30/files/用户手册.pdf' ">用户使用手册</p>-->
                </div>
            </div>
            <div class="ba"><img src="../assets/images/beian.png" alt="备案">
                <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery"> 黑ICP备2023008339号-1</a>
                          <p>指导单位：哈尔滨市工业和信息化局 &nbsp;&nbsp;  哈尔滨市营商环境建设监督局 &nbsp;&nbsp;哈尔滨经济技术开发区管委会</p>
                          <p> 主办单位：哈尔滨开发区中小企业融资担保有限公司 &nbsp;&nbsp;&nbsp;哈尔滨产融信息服务有限公司</p>
                          <p>技术支持单位：黑龙江省范式智能技术有限公司</p>
                          <p>征信服务机构：金电联行（北京）信息技术有限公司</p>
            </div>

        </div>
        <div @click="showPDF = false" style="position: fixed;top: 10px;right:140px;z-index: 2001;color:#fff" v-show="showPDF">
            <img style="width: 40px;height: 40px" src="../assets/images/close.png" alt="">
        </div>

        <iframe style="position: fixed;top: 0;left:0;z-index: 200" v-show="showPDF" :src='pdfSrc' width="100%" height="100%">
        </iframe>

        <a-row :gutter="24" class="main-box">

<!--           <a-col :span="6" class="main-left">-->
<!--               <a><img src="../assets/images/bot-logo.png" alt="LOGO"></a>-->
<!--               <p>Tel：123456</p>-->
<!--               <p>E-mail：123456@qq.com</p>-->
<!--               <p>合作联系：123456@qq.com</p>-->
<!--               <p>简历投递：123456@qq.com</p>-->
<!--               <p>友情链接：opensource.sensorsdata.cn</p>-->
<!--               <ul class="code">-->
<!--                   <li>-->
<!--                       <img src="" alt="">-->
<!--                       <span>关注公众号</span>-->
<!--                   </li>-->
<!--                   <li>-->
<!--                       <img src="" alt="">-->
<!--                       <span>在线咨询</span>-->
<!--                   </li>-->
<!--               </ul>-->
<!--               <p><img src="../assets/images/beian.png" alt="备案"> 备案服务号:黑ICP备20002043号-5</p>-->
<!--           </a-col>-->
<!--           <a-col :span="18" class="main-right">-->
<!--                <ul class="mr-top">-->
<!--                    <li>产品</li>-->
<!--                    <li>解决方案</li>-->
<!--                    <li>用户中心</li>-->
<!--                    <li>关于我们</li>-->
<!--                    <li>市场活动</li>-->
<!--                </ul>-->
<!--                <ul class="mr-bot">-->
<!--                    <li>-->
<!--                        <p>数据采集</p>-->
<!--                        <p>PaaS平台</p>-->
<!--                        <p>私有化部署</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <p>微信生态数字化运营</p>-->
<!--                        <p>通用数字化运营</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <p>产品上线指南</p>-->
<!--                        <p>使用手册</p>-->
<!--                        <p>技术文档</p>-->
<!--                        <p>资源图书馆</p>-->
<!--                        <p>博客文章</p>-->
<!--                        <p>GitHub</p>-->
<!--                        <p>SA 认证查询</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <p>公司介绍</p>-->
<!--                        <p>公司动态</p>-->
<!--                        <p>专家团队</p>-->
<!--                        <p>加入我们</p>-->
<!--                        <p>合作伙伴计划</p>-->
<!--                        <p>联系我们</p>-->
<!--                        <p>开源项目</p>-->
<!--                        <p>数据驱动大会</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <p>活动总览</p>-->
<!--                        <p>数据驱动大会</p>-->
<!--                        <p>行业专场</p>-->
<!--                        <p>主题活动</p>-->
<!--                    </li>-->
<!--                </ul>-->
<!--           </a-col>-->
        </a-row>


    </div>
</template>

<script>
    import {goLogoPic} from '@/api/login/login'
    export default {
        name:"Footer",
        data() {
            return {
                logob:require('../assets/images/home/logob.png'),
                showPDF: false,
                numPages: null, // pdf 总页数
                pdfSrc:'http://39.105.197.176:3080/crpt30/img/files/法律声明.pdf'
            }
        },
        mounted() {
            this.pdfSrc='http://39.105.197.176:3080/crpt30/img/files/法律声明.pdf'
        },
        methods: {

        },
        created() {
            goLogoPic({tm:new Date().getTime()}).then(res=>{
                if(res.result == 'success'){
                    this.logot = this.$baseUrl+'/'+res.varList[0].FILEPATH
                    this.logob = this.$baseUrl+'/'+res.varList[1].FILEPATH
                }
            })
        }
    }
</script>

<style lang="less" scoped>
.footer-box{
    background-color: @color-footer;
    overflow: hidden;
   .main-box{
        width: 1250px;
        margin:42px auto 20px!important;
      .fw{
          align-items: center;
          .line{
              width: 966px;
              height: 1px;
              img{
                  display: block;
                  width: 100%;
                  height: 100%  ;
              }
          }
          .fwr{
              text-align: right;
              font-size: 16px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: #27CFDD;
          }
      }
       .fb{
           margin-top: 35px;
           padding:0 150px 0 80px;
           display: flex;
           align-items: center;
           justify-content: space-between;
           .fbt{
               font-size: 18px;
               font-family: MicrosoftYaHei;
               font-weight: 400;
               color: #FFFFFF;
           }
           .fbc{
               font-size: 18px;
               font-family: MicrosoftYaHei;
               font-weight: 400;
               color: #999999;
           }
       }
       .ba{
           margin-top: 60px;
           text-align: center;
           font-size: 16px;
           font-family: MicrosoftYaHei;
           font-weight: 400;
           color: #999999;
           a{
               font-size: 18px;
               font-family: MicrosoftYaHei;
               font-weight: 400;
               color: #999999;
           }
       }
   }
}
</style>
